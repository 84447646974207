const config = {
  controls: [
    'zoom-in',
    'zoom-out',
    'fullscreen',
  ],
  iconUrl: `${'process.env.CDN_PATH'}${'process.env.SPRITES_NAME'}`,
  focusOnPlay: true,
};

const defaults = {
  config,
};

export default defaults;
