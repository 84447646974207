import {createElement} from './elements';
import {ZOOM_IN, ZOOM_OUT, FULLSCREEN} from './controls';
import loadSprite from './load-sprite';

const supportedControls = [ZOOM_IN, ZOOM_OUT, FULLSCREEN];

function createIconElement({iconName, classList}) {
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');

  svg.setAttribute('focusable', false);
  svg.setAttribute('class', classList.join(' '));

  const use = document.createElementNS('http://www.w3.org/2000/svg', 'use');
  use.setAttributeNS('http://www.w3.org/1999/xlink', 'path', `#${iconName}`);
  use.setAttributeNS('http://www.w3.org/1999/xlink', 'xlink:href', `#${iconName}`);
  svg.appendChild(use);
  return svg;
}

const ui = {
  build(mvui) {
    const {viewer, elements, container, config} = mvui;
    const {controls, iconUrl} = config;

    const zoomLevel = createElement('div', {class: 'shopify-model-viewer-ui__sr-only', 'data-shopify-model-viewer-ui-sr-only': '', role: 'status'});
    const controlsOverlay = createElement('div', {class: 'shopify-model-viewer-ui__controls-overlay'});

    container.insertBefore(zoomLevel, viewer);
    container.appendChild(controlsOverlay);

    this.buildControlButton(mvui, controlsOverlay);

    if (!controls || !Array.isArray(controls) || !controls.length) {
      return;
    }

    const unknownControls = controls.filter((control) => !(supportedControls.includes(control)));
    if (unknownControls.length > 0) {
      throw Error(`Unrecognized controls passed to ModelViewerUI: ${unknownControls.join(', ')}`);
    }

    loadSprite(iconUrl, 'sprites-mvui').then((spritesElement) => {
      elements.sprites = spritesElement;
    }).catch(() => {
      throw Error('Failed to load sprite from URL');
    });

    elements.zoomLevel = zoomLevel;
    this.buildControlList(mvui, controlsOverlay);
  },

  buildControlList(mvui, controlsOverlay) {
    const {controls} = mvui.config;

    const controlArea = createElement('div', {class: 'shopify-model-viewer-ui__controls-area'});
    controlsOverlay.appendChild(controlArea);

    controls.forEach((control) => {
      const button = createElement('button', {class: `shopify-model-viewer-ui__button shopify-model-viewer-ui__button--control shopify-model-viewer-ui__button--${control}`});

      switch (control) {
        case ZOOM_IN:
          // eslint-disable-next-line no-undef
          button.setAttribute('aria-label', __('zoom_in'));
          button.appendChild(createIconElement({
            iconName: 'zoom-in-icon',
            classList: ['shopify-model-viewer-ui__control-icon'],
          }));
          break;

        case ZOOM_OUT:
          // eslint-disable-next-line no-undef
          button.setAttribute('aria-label', __('zoom_out'));
          button.appendChild(createIconElement({
            iconName: 'zoom-out-icon',
            classList: ['shopify-model-viewer-ui__control-icon'],
          }));
          break;

        case FULLSCREEN:
          // eslint-disable-next-line no-undef
          button.setAttribute('aria-label', __('enter_fullscreen'));
          button.appendChild(createIconElement({
            iconName: 'enter-fullscreen-icon',
            classList: ['shopify-model-viewer-ui__control-icon', 'shopify-model-viewer-ui__control-icon--enter-fullscreen'],
          }));

          button.appendChild(createIconElement({
            iconName: 'exit-fullscreen-icon',
            classList: ['shopify-model-viewer-ui__control-icon', 'shopify-model-viewer-ui__control-icon--exit-fullscreen'],
          }));
          break;
      }

      mvui.elements.buttons[control] = button;
      controlArea.appendChild(button);
    });

    mvui.elements.controlArea = controlArea;
  },

  buildControlButton(mvui, controlsOverlay) {
    const {elements} = mvui;

    const button = createElement('button', {class: 'shopify-model-viewer-ui__button shopify-model-viewer-ui__button--poster'});

    // eslint-disable-next-line no-undef
    button.setAttribute('aria-label', __('play_viewer'));
    button.appendChild(createIconElement({
      iconName: 'threed-icon-button-control',
      classList: ['shopify-model-viewer-ui__poster-control-icon'],
    }));

    elements.controlButton = button;
    controlsOverlay.appendChild(button);
  },
};

export default ui;
