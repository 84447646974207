import is from './is';

const MOUSE_MOVE_THRESHOLD_IN_PIXELS = 4;

function areCoordinatesAboveThreshold(initial, current) {
  return (
    (Math.abs(initial.x - current.x) > MOUSE_MOVE_THRESHOLD_IN_PIXELS) ||
    (Math.abs(initial.y - current.y) > MOUSE_MOVE_THRESHOLD_IN_PIXELS)
  );
}

const initialState = {
  isDragging: false,
  cursorStartPosition: null,
};

export default class MouseEventListeners {
  constructor(mvui) {
    this.mvui = mvui;
    this.state = {...initialState};

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  add(state = initialState) {
    const {container} = this.mvui;
    this.state = {...state};

    container.addEventListener('mousedown', this.onMouseDown);
    container.addEventListener('mousemove', this.onMouseMove);
    container.addEventListener('mouseup', this.onMouseUp);
  }

  remove() {
    const {container} = this.mvui;

    container.removeEventListener('mousedown', this.onMouseDown);
    container.removeEventListener('mousemove', this.onMouseMove);
    container.removeEventListener('mouseup', this.onMouseUp);
  }

  onMouseDown(event) {
    this.resetState();
    this.state.cursorStartPosition = {x: event.pageX, y: event.pageY};
  }

  onMouseMove(event) {
    const {cursorStartPosition} = this.state;
    if (is.nullOrUndefined(cursorStartPosition)) {
      return;
    }

    if (areCoordinatesAboveThreshold(cursorStartPosition, {x: event.pageX, y: event.pageY})) {
      this.state.isDragging = true;
    }
  }

  onMouseUp(event) {
    const {mvui} = this;
    const {interacting, elements, toggleable} = mvui;
    const {controlArea} = elements;
    const {isDragging} = this.state;

    if (controlArea.contains(event.target)) {
      this.resetState();
      return;
    }

    if (!interacting && !isDragging) {
      mvui.play();
    } else if (toggleable && interacting && !isDragging) {
      mvui.pause();
    }

    this.resetState();
  }

  resetState() {
    this.state = {...initialState};
  }
}
