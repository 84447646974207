import {copyTouch, findTouchById} from './touch-utils';

const TOUCH_MOVE_THRESHOLD_IN_PIXELS = 10;

function areCoordinatesAboveThreshold(initial, current) {
  return (
    (Math.abs(initial.x - current.x) > TOUCH_MOVE_THRESHOLD_IN_PIXELS) ||
    (Math.abs(initial.y - current.y) > TOUCH_MOVE_THRESHOLD_IN_PIXELS)
  );
}

const initialState = {
  isDragging: false,
  isPinchZooming: false,
  ongoingTouches: [],
  touchStartPositions: [],
};

export default class TouchEventListeners {
  constructor(mvui) {
    this.mvui = mvui;
    this.state = {...initialState};

    this.onTouchStart = this.onTouchStart.bind(this);
    this.onTouchMove = this.onTouchMove.bind(this);
    this.onTouchEnd = this.onTouchEnd.bind(this);
    this.resetState = this.resetState.bind(this);
  }

  add(state = initialState) {
    const {container} = this.mvui;
    this.state = {...state};

    container.addEventListener('touchstart', this.onTouchStart);
    container.addEventListener('touchmove', this.onTouchMove);
    container.addEventListener('touchend', this.onTouchEnd);
  }

  remove() {
    const {container} = this.mvui;

    container.removeEventListener('touchstart', this.onTouchStart);
    container.removeEventListener('touchmove', this.onTouchMove);
    container.removeEventListener('touchend', this.onTouchEnd);
  }

  onTouchStart(event) {
    const {ongoingTouches, touchStartPositions} = this.state;
    const {changedTouches} = event;
    for (let i = 0; i < changedTouches.length; i++) {
      const newTouch = copyTouch(changedTouches[i]);
      ongoingTouches.push(newTouch);
      touchStartPositions.push({
        x: newTouch.pageX,
        y: newTouch.pageY,
      });
    }
  }

  onTouchMove(event) {
    const {changedTouches} = event;
    const {ongoingTouches, touchStartPositions} = this.state;
    if (ongoingTouches.length >= 2) {
      this.state.isPinchZooming = true;
    }

    for (let i = 0; i < changedTouches.length; i++) {
      const {pageX, pageY, identifier} = changedTouches[i];
      const foundTouchIndex = findTouchById(ongoingTouches, identifier);
      if (foundTouchIndex === -1) {
        continue;
      }

      const initialTouchPosition = touchStartPositions[foundTouchIndex];
      if (areCoordinatesAboveThreshold(initialTouchPosition, {x: pageX, y: pageY})) {
        this.state.isDragging = true;
      }
    }
  }

  onTouchEnd({changedTouches, target}) {
    const {mvui} = this;
    const {interacting, elements, toggleable} = mvui;
    const {controlArea} = elements;
    const {
      isDragging,
      isPinchZooming,
      ongoingTouches,
      touchStartPositions,
    } = this.state;

    for (let i = 0; i < changedTouches.length; i++) {
      const foundTouchId = findTouchById(ongoingTouches, changedTouches[i].identifier);
      if (foundTouchId === -1) {
        continue;
      }

      ongoingTouches.splice(foundTouchId, 1);
      touchStartPositions.splice(foundTouchId, 1);
    }

    if (ongoingTouches.length === 0) {
      this.resetState();
    }

    if (controlArea.contains(target)) {
      return;
    }

    if (!interacting && !isDragging && !isPinchZooming) {
      mvui.play();
    } else if (toggleable && interacting && !isDragging && !isPinchZooming) {
      mvui.pause();
    }
  }

  resetState() {
    this.state = {...initialState};
  }
}
