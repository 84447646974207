import is from './is';
import InstanceCache from './instance-cache';
import {
  createElement,
  removeElement,
  wrap,
  toggleHidden,
  toggleClass,
  replaceElement,
} from './elements';
import defaults from './defaults';
import ui from './ui';
import events from './events';
import {
  MVUI_PLAY,
  MVUI_PAUSE,
} from './custom-events';
import isFullscreen from './is-fullscreen';
import {FULLSCREEN} from './controls';

const instanceCache = new InstanceCache();

const MIN_ZOOM_FOV_IN_DEGREES = 10;
const MAX_ZOOM_FOV_IN_DEGREES = 45;

function viewerFromTarget(target) {
  let viewer = target;

  if (is.string(viewer)) {
    viewer = document.querySelector(viewer);
  }

  if (is.jqueryObject(viewer)) {
    viewer = viewer[0];
  }

  if (is.array(viewer) || is.nodeList(viewer)) {
    throw Error('Use static setup method when using an array or nodeList of elements');
  }

  if (is.nullOrUndefined(viewer) || !is.element(viewer)) {
    throw Error('Please pass in a query selector, element, or jQuery instance');
  }

  return viewer;
}

function isMobileDevice() {
  return (typeof window.orientation !== "undefined") ||
    (navigator.userAgent.indexOf('IEMobile') !== -1);
}

class ModelViewerUI {
  constructor(target, options = {}) {
    this.config = {...defaults.config, ...options};

    const viewer = viewerFromTarget(target);

    const cachedUI = instanceCache.getCachedInstance(viewer);
    if (cachedUI) {
      return cachedUI;
    }

    const reveal = viewer.getAttribute('reveal');
    const toggleable = Boolean(viewer.getAttribute('toggleable'));

    this.state = {
      elements: {
        original: viewer.cloneNode(true),
        buttons: {},
        container: null,
        controlButton: null,
        controlArea: null,
        zoomLevel: null,
        sprites: null,
      },
      viewerProperties: {
        reveal,
        interaction: viewer.getAttribute('interaction'),
        orbit: viewer.cameraOrbit,
        fov: viewer.fieldOfView,
        zoomLevelTimeout: null,
      },
      viewer,
      interacting: (reveal !== 'interaction'),
      toggleable: is.boolean(toggleable) ? toggleable : false,
      modelIsVisible: false,
    };

    this.defineProperties();

    if (!is.element(this.container)) {
      const element = createElement('div', {
        class: 'shopify-model-viewer-ui',
      });

      if (!isMobileDevice()) {
        element.classList.add('shopify-model-viewer-ui--desktop');
      }

      wrap(this.viewer, element);
      this.container = element;
    }

    instanceCache.cacheInstance(this);

    // Build out the UI...
    ui.build(this);

    // ...then add event handlers and actions
    events.add(this);

    if (this.interacting) {
      toggleHidden(this.elements.controlButton, true);
    } else {
      toggleHidden(this.elements.controlButton, false);
      toggleClass(viewer, 'shopify-model-viewer-ui__disabled', true);
      viewer.setAttribute('tabindex', '-1');
    }
  }

  destroy() {
    if (!this.state) {
      return;
    }

    const {sprites, original} = this.state.elements;

    if (sprites) {
      removeElement(sprites);
    }

    replaceElement(original, this.container);

    events.remove(this);

    instanceCache.removeFromCache(this.viewer);

    this.state = null;
  }

  zoom(amount) {
    const {viewer, elements, viewerProperties} = this;

    let fov = viewer.getFieldOfView();
    fov += amount;
    const clampedFov = Math.min(Math.max(fov, MIN_ZOOM_FOV_IN_DEGREES), MAX_ZOOM_FOV_IN_DEGREES);
    viewer.fieldOfView = `${clampedFov}deg`;

    const zoomPercentage = (clampedFov - MAX_ZOOM_FOV_IN_DEGREES) * 100 / (MIN_ZOOM_FOV_IN_DEGREES - MAX_ZOOM_FOV_IN_DEGREES);
    // eslint-disable-next-line no-undef
    elements.zoomLevel.innerText = `${Math.round(zoomPercentage)}% ${__('zoomed')}.`;

    clearTimeout(viewerProperties.zoomLevelTimeout);
    viewerProperties.zoomLevelTimeout = setTimeout(() => {
      elements.zoomLevel.innerText = '';
    }, 5000);
  }

  toggleFullscreen() {
    const {container, elements} = this;
    const toggleFullscreenButton = elements.buttons[FULLSCREEN];

    if (isFullscreen()) {
      toggleFullscreenButton.setAttribute('aria-label', 'Enter Fullscreen');

      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    } else {
      toggleFullscreenButton.setAttribute('aria-label', 'Exit Fullscreen');

      if (container.requestFullscreen) {
        container.requestFullscreen();
      } else if (container.mozRequestFullScreen) {
        container.mozRequestFullScreen();
      } else if (container.webkitRequestFullscreen) {
        container.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    }
  }

  play() {
    const {elements, viewer, modelIsVisible} = this;
    const {controlButton, controlArea} = elements;

    if (viewer.getAttribute('reveal') === 'interaction') {
      viewer.setAttribute('reveal', 'auto');
    }

    toggleHidden(controlButton, true);

    viewer.dispatchEvent(new CustomEvent(MVUI_PLAY, {detail: {modelViewerUI: this}}));

    toggleClass(viewer, 'shopify-model-viewer-ui__disabled', false);

    viewer.setAttribute('tabindex', '0');

    if (this.config.focusOnPlay) {
      viewer.focus();
    }

    if (modelIsVisible) {
      toggleClass(controlArea, 'shopify-model-viewer-ui__controls-area--playing', true);
    }

    this.interacting = true;
  }

  pause() {
    const {elements, viewer, viewerProperties} = this;
    const {controlButton, controlArea} = elements;
    const {orbit, fov} = viewerProperties;

    toggleHidden(controlButton, false);

    viewer.dispatchEvent(new CustomEvent(MVUI_PAUSE, {detail: {modelViewerUI: this}}));

    toggleClass(viewer, 'shopify-model-viewer-ui__disabled', true);
    toggleClass(controlArea, 'shopify-model-viewer-ui__controls-area--playing', false);
    viewer.setAttribute('tabindex', '-1');
    viewer.blur();
    viewer.cameraOrbit = orbit;
    viewer.fieldOfView = fov;
    viewer.pause();

    this.interacting = false;
  }

  defineProperties() {
    Object.defineProperty(this, 'elements', {
      value: this.state.elements, writable: false,
    });

    Object.defineProperty(this, 'viewer', {
      value: this.state.viewer, writable: false,
    });

    Object.defineProperty(this, 'viewerProperties', {
      value: this.state.viewerProperties, writable: false,
    });

    Object.defineProperty(this, 'toggleable', {
      value: this.state.toggleable, writable: false,
    });

    Object.defineProperty(this, 'interacting', {
      value: this.state.interacting, writable: true,
    });

    Object.defineProperty(this, 'container', {
      value: this.elements.container, writable: true,
    });
  }

  get modelIsVisible() {
    return this.state.modelIsVisible;
  }

  set modelIsVisible(value) {
    this.state.modelIsVisible = value;

    const {controlArea, controlButton} = this.elements;

    if (value && !is.nullOrUndefined(controlArea)) {
      toggleHidden(controlButton, true);
      toggleClass(controlArea, 'shopify-model-viewer-ui__controls-area--playing', true);
    }
  }

  static setup(selector, options = {}) {
    let targets = null;

    if (is.string(selector)) {
      targets = Array.from(document.querySelectorAll(selector));
    } else if (is.nodeList(selector)) {
      targets = Array.from(selector);
    } else if (is.array(selector)) {
      targets = selector.filter(is.element);
    }

    if (is.empty(targets)) {
      return null;
    }

    return targets.map((t) => new ModelViewerUI(t, options));
  }
}

export default ModelViewerUI;
